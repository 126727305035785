<template>
    <div class="mx-4" v-if="currentUser">
        <Form @submit="submitComment" class="pt-2" :validation-schema="validationSchema" ref="form">
            <div class="pb-1 ">Submit comment for <router-link
                    :to="{ name: 'ProfileWithSlug', params: { slug: currentUser.slug } }" class="bleez-text">@{{
                        currentUser.member_name }}</router-link></div>
            <div class="">
                <Field v-slot="{ field, errors }" v-model="comment" name="comment" rules="required">
                    <textarea v-bind="field" name="comment"
                        class="border boder-slat-300 w-full h-96 px-2 py-1 focus-within:outline-none" />
                    <div v-if="errors[0]" class="text-red-500 text-sm">{{ errors[0] }}</div>
                </Field>
                <!-- <ErrorMessage name="comment" /> -->
            </div>

            <div class="mt-8 mb-4 text-right">
                <button class="bg-btn rap-btn" :disabled="processing">Submit</button>
            </div>
        </Form>
        <div class="absolute z-50 h-full w-full inset-0 bg-black bg-opacity-75" v-if="showCropper">
            <div class="w-10/12 h-4/6 mx-auto relative mt-20">
                <Cropper ref="cropper" minWidth="500" minHeight="500" :stencil-props="{ aspectRatio: 1 / 1, }"
                    :src="previewAvatar" :canvas="{
                        minHeight: 0,
                        minWidth: 0,
                        maxHeight: 2048,
                        maxWidth: 2048,
                    }">
                </Cropper>
                <!-- <vue-cropper ref="cropper" :src="previewAvatar" :aspect-ratio="1 / 1" minCropBoxWidth="200"
            minCropBoxHeight="200">
          </vue-cropper> -->
                <div class="mt-8 mb-4 text-right">
                    <button class="bg-btn mr-4 bg-white rounded font-bold" @click="closeCropper()">
                        Cancel
                    </button>
                    <button class="bg-btn rapbg rounded font-bold" @click="cropImage()">Select</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import API from "../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as yup from "yup";
import { Field, Form } from "vee-validate";


export default {
    name: "ArtistSubmitComment",
    setup() {
        const toast = useToast();
        return { toast };
    },
    components: {
        Field,
        Form,
    },
    computed: {
        user() {
            return this.$store.getters["User/user"];
        },
        currentUser() {
            return this.$store.getters["Profile/currentUser"][this.$route.params.slug];
        },
    },
    data() {
        const schema = yup.object({
            comment: yup.string().required().min(10),
        });

        return {
            artist: null,
            comment: '',
            validationSchema: schema,
        };
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let url =
                this.user && this.user.public_id ? "get-artist-info/" : "guest-get-artist-info/";
            API.get(url + this.$route.params.slug).then((response) => {
                this.$store.commit("Profile/SET_ARTIST", response);
                this.$store.commit("Profile/SET_LOADING", {
                    status: false,
                    slug: this.$route.params.slug,
                });
            });
        },
        submitComment() {
            this.processing = true;

            API.post("comments", { comment: this.comment, artist_id: this.currentUser.id })
                .then((response) => {
                    this.processing = false;
                    this.toast.success(response.message);
                    this.$router.push({
                        name: "ProfileWithSlug",
                        params: { slug: this.$route.params.slug },
                    });
                })
                .catch(() => {
                    this.processing = false;
                });
        },
    },
};
</script>
  